<template>
  <div class="yimiao">
    <div>
      <div style="margin-bottom: 60px">
        <van-cell-group>
          <van-field
            v-model="datafrom.name"
            label=" 姓 名 "
            required
            placeholder="请输入姓名"
          />
          <van-cell-group>
            <van-field
              v-model="datafrom.certNumber"
              label="身份证号"
              required
              placeholder="请输入身份证号码"
            />
          </van-cell-group>
        </van-cell-group>

        <!-- 全部 -->
        <div
          class="center"
          v-for="item in list"
          :key="item.index"
          style="
            border: 1px solid rgb(222 206 206);
            width: 95%;
            margin: 5px auto;
            border-radius: 5px;
          "
        >
          <div class="Content" style="padding: 8px 10px">
            <div style="display: inline-block">
              <span class="centertime" style="color: #333; font-size: 17px">
                姓名：{{ item.Name }}
              </span>
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                采集时间：{{ item.AddTime }}
              </div>
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                出生日期：{{ item.Birthday }}
              </div>
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                性别：{{ item.Sex }}
              </div>
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                检测结果：{{ item.CheckResultDesc }}
              </div>
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                采检机构：{{ item.UName }}
              </div>
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                检测时间：{{ item.CheckTime }}
              </div>
            </div>
            <div class="buttons" style="width: 100%">
              <van-button
                type="primary"
                style="margin: 10px 0"
                @click="updata(item.CRId)"
                >下载报告</van-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="buttons" style="position: fixed; bottom: 0px; width: 100%">
        <van-button type="info" style="margin: 10px 0" @click="postfrom()"
          >查询</van-button
        >
      </div>
      <!-- <div id="demo"
        class="m-pdf"></div> -->
      <iframe :src="pdf" width="100%" height="100%" style="display: none">
        <a href="/test.pdf" rel="external nofollow"></a>
      </iframe>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
Vue.use(Toast);
import { SearchReport, WxExportReportToPdf } from "@/api/dayao";
import Pdfh5 from "pdfh5";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      datafrom: {
        name: "",
        certNumber: "",
      },
      list: [],
      pdf: "",
    };
  },
  created() {
    console.log(config.apiHttpsUrl);
  },
  methods: {
    postfrom: function () {
      if (this.datafrom.certNumber == "") {
        Toast.fail("请输入身份证号");
        return false;
      }
      if (this.datafrom.name == "") {
        Toast.fail("请输入姓名");
        return false;
      }

      console.log(this.datafrom);
      SearchReport(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          this.list = res.data.data;
          Dialog.alert({
            title: "提示",
            message: "查询成功",
          }).then(() => {
            // this.$router.go(-1);
            // this.$router.push({
            //   name: "register",
            // });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "查询失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    // 下载报告
    updata(row) {
      console.log(row);
      this.$router.push({
        path: "/dayao/dayaoShouYe/dayaoPdf/" + row,
      });
      //     WxExportReportToPdf({crId:row}).then((res) => {
      //     if (res.data.code == 0) {
      //       this.pdf = config.apiHttpsUrl +  res.data.data
      //       Dialog.alert({
      //         title: "提示",
      //         message: "请按提示进行下载",
      //       }).then(() => {
      //         // this.$router.go(-1);
      //         // var itemmark = JSON.stringify(this.pdf);
      //         // console.log(itemmark);
      //         // this.$router.push({
      //         //   path: "/dayao/dayaoShouYe/dayaoPdf/"+ itemmark,
      //         // });
      //       });
      //     } else {
      //       Dialog.alert({
      //         title: "提示",
      //         message: "操作失败!" + res.data.msg,
      //       }).then(() => {
      //         // on close
      //       });
      //     }
      //   });
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>